import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { find, cloneDeep, some, map, join, isEmpty, compact } from 'lodash';
import { stringify } from 'query-string';
import { createPortal } from 'react-dom';

import { CurrencyMap, CardTypeImagePath, apiToLocalMoment, localToApiMoment } from 'common/utilities';
import { transactionsFilter } from 'common/components/transactions/filter/transactionsFilter';
import { ReactToPrint } from 'common/components/react-to-print';
import { PrintGridData } from 'common/components/print-grid-data';

const { apiDateFormat, parseDateTimeFormat, displayDateTimeFormat } = ApplicationSettings;

class BatchGridRow extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isPrinting: false,
		};

		this.setState = this.setState.bind(this);
	}

	get cardTypes() {
		return ['Visa', 'MC', 'Discover', 'Amex', 'EBT', 'EWic', 'Debit', 'Ach', 'EBTW'];
	}

	currency = CurrencyMap.resolveCurrency();

	getFilters = async () => {
		const {
			row: { xBatch, xBatchDate, xBatchTime },
			batches,
		} = this.props;
		const batchFilter = cloneDeep(find(transactionsFilter, { key: 'batch' }));
		batchFilter.hasSelection = true;
		if (xBatch) {
			batchFilter.values.batch = xBatch;
		} else if (!isEmpty(batches)) {
			batchFilter.values.batch = join(batches, ',');
		}

		const dateFilter = cloneDeep(find(transactionsFilter, { key: 'date' }));
		dateFilter.hasSelection = true;
		if (xBatchDate) {
			const date = `${xBatchDate} ${xBatchTime ? xBatchTime : '12:00:00 PM'}`;
			dateFilter.values.key = xBatchDate;
			if (xBatchTime) {
				dateFilter.values.startDate = moment(date)
					.subtract(29, 'days')
					.startOf('day');
				dateFilter.values.endDate = moment(date).endOf('day');
			} else {
				let startDate = (await localToApiMoment(date, ApplicationSettings.displayDateTimeFormat))
					.startOf('day')
					.subtract(29, 'days');
				dateFilter.values.startDate = await apiToLocalMoment(startDate);
				let endDate = (await localToApiMoment(date, ApplicationSettings.displayDateTimeFormat)).endOf('day');
				dateFilter.values.endDate = await apiToLocalMoment(endDate);
			}
		} else {
			dateFilter.values.key = '';
			dateFilter.values.startDate = (await apiToLocalMoment(moment())).subtract(29, 'days').startOf('day');
			dateFilter.values.endDate = (await apiToLocalMoment(moment())).endOf('day');
		}
		delete dateFilter.customSettingsKey;
		const startDate = dateFilter.values.startDate.format(parseDateTimeFormat);
		const endDate = dateFilter.values.endDate.format(parseDateTimeFormat);

		return {
			key: dateFilter.values.key,
			startDate,
			endDate,
			...batchFilter.values,
		};
	};

	viewBatchTransactions = async evt => {
		evt.stopPropagation();
		const filters = await this.getFilters();
		window.open(`/transactions?${stringify(filters)}`, '_blank');
	};

	usToIsoDate = date => {
		return moment(date, displayDateTimeFormat).format(apiDateFormat);
	};

	renderAmountAndCount = (amount, count) => {
		return (
			<div className="flex--primary flex--nowrap">
				<NumberFormat
					prefix={this.currency}
					value={amount || 0}
					displayType="text"
					thousandSeparator={true}
					decimalScale={2}
					fixedDecimalScale={true}
					className="type--p2 type--p2--medium spc--right--sml"
				/>
				<span className="batches__table__count datatooltip--auto" data-tooltip="Count">
					{count || 0}
				</span>
			</div>
		);
	};

	handlePrintError = (method, error) => {
		const { handleError } = this.props;
		if (handleError(error, { additionalInfo: { method } })) {
			this.setState({ isPrinting: false });
		}
	};

	handleAfterPrint = () => {
		this.setState({ isPrinting: false });
	};

	handleOnBeforeGetContent = () => {
		return new Promise(resolve => {
			this.setState({ isPrinting: true }, resolve);
		});
	};

	handleExpandDetails = () => {
		const { xBatch, index } = this.props.row;
		this.props.toggleExpand(xBatch || index);
	};

	getCount = (row, cardType, transactionType) => row[`x${cardType}${transactionType}Count`];

	getCounts = (row, cardType, ...transactionTypes) =>
		some(transactionTypes, transactionType => transactionType && this.getCount(row, cardType, transactionType));

	mapRowDetailsForPrint = () => {
		const { row, showVoid } = this.props;

		return compact(map(this.cardTypes, type => this.mapSubRowForPrint(row, type, showVoid)));
	};

	mapSubRowForPrint = (row, cardType, showVoid) =>
		this.getCounts(row, cardType, 'Sale', 'Credit', this.props.showVoid && 'Void')
			? {
					xCardType: cardType,
					...this.mapAmountsAndCounts(row, cardType, showVoid),
			  }
			: null;

	mapAmountsAndCounts = (row, cardType, showVoid) => {
		const mappedRow = {
			xTotalAmount: row[`x${cardType}TotalAmount`],
			xTotalCount: row[`x${cardType}TotalCount`],
			xSaleAmount: row[`x${cardType}SaleAmount`],
			xSaleCount: row[`x${cardType}SaleCount`],
			xCreditAmount: row[`x${cardType}CreditAmount`],
			xCreditCount: row[`x${cardType}CreditCount`],
		};

		if (showVoid) {
			mappedRow.xVoidAmount = row[`x${cardType}VoidAmount`];
			mappedRow.xVoidCount = row[`x${cardType}VoidCount`];
		}

		return mappedRow;
	};

	renderAmountsAndCounts = (row, cardType, showVoid, showProcessingFee) => (
		<Fragment>
			<td className="right type--wgt--bold">
				{this.renderAmountAndCount(row[`x${cardType}TotalAmount`], row[`x${cardType}TotalCount`])}
			</td>
			{showProcessingFee ? (
				<Fragment>
					<td className="right">
						{this.renderAmountAndCount(
							row[`x${cardType}TotalProcessingFeeAmount`],
							row[`x${cardType}TotalProcessingFeeCount`]
						)}
					</td>
					<td className="right">
						{this.renderAmountAndCount(row[`x${cardType}NetTotalAmount`], row[`x${cardType}NetTotalCount`])}
					</td>
				</Fragment>
			) : null}
			<td className="right">
				{this.renderAmountAndCount(row[`x${cardType}SaleAmount`], row[`x${cardType}SaleCount`])}
			</td>
			{showVoid ? (
				<td className="right">
					{this.renderAmountAndCount(row[`x${cardType}VoidAmount`], row[`x${cardType}VoidCount`])}
				</td>
			) : null}
			<td className="right">
				{this.renderAmountAndCount(row[`x${cardType}CreditAmount`], row[`x${cardType}CreditCount`])}
			</td>
		</Fragment>
	);

	renderSubRow = (row, cardType, showVoid, showProcessingFee) =>
		this.getCounts(row, cardType, 'Sale', 'Credit', this.props.showVoid && 'Void') ? (
			<tr key={cardType} className="table--primary__expanded-content">
				{this.props.displayEmptyIdColumn && <td></td>}
				<td>
					<img src={CardTypeImagePath.getPath(cardType)} className="grid__creditcard" />
				</td>
				{this.renderAmountsAndCounts(row, cardType, showVoid, showProcessingFee)}
				<td></td>
			</tr>
		) : null;

	renderPrintButton = () => {
		const { isPrinting } = this.state;
		return (
			<ReactToPrint
				trigger={() => (
					<button
						type="button"
						disabled={isPrinting}
						className="btn btn--link spc--right--lrg datatooltip--auto"
						data-tooltip="Print"
					>
						<i className="icon icon--sml icon--print--light" />
					</button>
				)}
				content={() => this.print}
				onPrintError={this.handlePrintError}
				onBeforeGetContent={this.handleOnBeforeGetContent}
				onAfterPrint={this.handleAfterPrint}
				onClick={e => {
					e.stopPropagation();
					e.preventDefault();
				}}
			/>
		);
	};

	renderViewBatchTransactionsButton = () => (
		<a className="btn btn--link" onClick={this.viewBatchTransactions}>
			<i className="icon icon--sml icon--away--light"></i>
		</a>
	);

	renderRowDetails = () => {
		const { row, showVoid, showProcessingFee } = this.props;
		const rowClassName = '';
		const cellClassName = '';

		return map(this.cardTypes, type =>
			this.renderSubRow(row, type, showVoid, showProcessingFee, rowClassName, cellClassName)
		);
	};

	render() {
		const { isPrinting } = this.state;
		const { row, showVoid, showProcessingFee, columns, portal, expanded } = this.props;
		const isRowExpanded = expanded[row.xBatch || row.index];
		const linkExpandedClass = isRowExpanded ? 'down' : 'right';
		const rowExpandedClass = isRowExpanded ? 'is-expanded' : '';

		return (
			<Fragment>
				<tr id={`batch-id-${row.xBatch || row.index}`} className={rowExpandedClass} onClick={this.handleExpandDetails}>
					{row.xBatch ? (
						<td>
							<button onClick={this.handleExpandDetails} className="btn btn--link btn--link--tertiary">
								<i className={`icon icon--sml icon--chevron--${linkExpandedClass}--primary spc--right--sml--alt`}></i>
								{row.xBatch}
							</button>
						</td>
					) : null}
					<td>
						{row.xBatch ? null : (
							<span onClick={this.handleExpandDetails} className="">
								<i className={`icon icon--sml ${linkExpandedClass} spc--right--tny`}></i>
							</span>
						)}
						{row.xBatchDate || row.xBatchTime ? (
							<span className="type--color--primary">
								{row.xBatchDate} {row.xBatchTime}
							</span>
						) : (
							<span>----</span>
						)}
					</td>
					{this.renderAmountsAndCounts(row, '', showVoid, showProcessingFee)}
					<td className="right">
						<div className="flex--primary flex--nowrap">
							{this.renderPrintButton()}
							{this.renderViewBatchTransactionsButton()}
						</div>
					</td>
				</tr>
				{isRowExpanded ? this.renderRowDetails() : null}
				{portal &&
					createPortal(
						<PrintGridData
							ref={el => (this.print = el)}
							data={isRowExpanded && isPrinting ? [row, ...this.mapRowDetailsForPrint()] : [row]}
							columns={columns}
							title="Batch Details"
							type="batchDetails"
							expandedRows={expanded}
						/>,
						portal
					)}
			</Fragment>
		);
	}
}

BatchGridRow.propTypes = {
	row: PropTypes.object.isRequired,
	columns: PropTypes.array.isRequired,
	expanded: PropTypes.object.isRequired,
	toggleExpand: PropTypes.func.isRequired,
	showVoid: PropTypes.bool,
	showProcessingFee: PropTypes.bool,
	batches: PropTypes.arrayOf(PropTypes.number),
	handleError: PropTypes.func,
	displayEmptyIdColumn: PropTypes.bool.isRequired,
	portal: PropTypes.any,
};

export default BatchGridRow;
