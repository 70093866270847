import React, { Component, createRef, Fragment } from 'react';
import { filter, isEmpty, map, toLower, last, each, camelCase, transform, first, get, split, zip, find } from 'lodash';
import { any, array, func, string } from 'prop-types';

import IpInput from '../ip-input/ip-input';
import { FormErrors } from 'common/utilities';
import { withCancelable } from '../cancelable';
import { keyManagementService } from 'common/services';

const requestKeys = {
	SAVE: 'save',
	LOAD: 'load',
};

class WhitelistIPsPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			whiteListedIPs: {
				allIPs: '',
				areAllIPsValid: true,
			},
			isSaving: false,
		};

		this.errorRef = createRef();
	}

	setIsSaving = (isSaving = false) => this.setState({ isSaving });

	save = async () => {
		try {
			const {
				whiteListedIPs: { allIPs },
			} = this.state;
			const { data, makePendingRequest, addNotification, showLoader, type } = this.props;

			this.setIsSaving(true);
			showLoader(true);
			const users = await makePendingRequest(
				Promise.all(map(data, ({ key, key2 }) => keyManagementService.load(null, key || key2))),
				requestKeys.LOAD
			);
			const mappedUsers = map(users, ({ data }) => transform(data, (acc, value, key) => (acc[camelCase(key)] = value)));

			if (!this.checkFormValidity()) {
				return;
			}

			const results = await makePendingRequest(
				Promise.all(
					map(mappedUsers, ({ ddbRevision: ddbrevision, key2, index1, keyType: keytype, role, keyDescription }) =>
						keyManagementService.save({
							key2,
							role,
							ddbrevision,
							index1,
							keytype,
							keyDescription,
							whiteListedIPs: allIPs.replace(/\n/g, ','),
						})
					)
				),
				requestKeys.SAVE
			);

			const zippedResults = zip(mappedUsers, results);
			const successes = filter(zippedResults, ([, { result }]) => toLower(result) === 'success');
			const errors = filter(zippedResults, ([, { result }]) => toLower(result) !== 'success');
			const successMessage = (message = `User${successes.length > 1 ? 's' : ''}`) =>
				allIPs ? (
					<Fragment>
						<div>
							{message} successfully whitelisted with the following IP address
							{split(allIPs, ',').length > 1 ? 'es' : ''}:
						</div>
						<div>{allIPs}</div>
					</Fragment>
				) : (
					'Successfully updated IP whitelist.'
				);

			if (isEmpty(errors) && !isEmpty(successes)) {
				addNotification({
					message: successMessage(),
					ref: get(first(results, {}), 'refnum'),
					contentClassName: 'fullwidth w--max--570',
					alignLeft: true,
					success: true,
				});
			} else if (!isEmpty(errors)) {
				let [firstNotification, ...otherNotifications] = map(errors, ([{ key2, keyDescription }, error]) => {
					const userEmail = get(find(data, { key2 }), 'xEmail', '');
					return this.props.handleError(error, {
						delayMessage: true,
						additionalInfo: { key: type === 'users' ? userEmail : keyDescription },
					});
				});
				const joinMessage = ({ additionalInfo: { key }, message }) => (
					<div>
						{key}: {message}
					</div>
				);
				firstNotification.message = joinMessage(firstNotification);
				firstNotification.children = [];
				if (!isEmpty(successes)) {
					const oldFirstNotification = firstNotification;
					delete firstNotification.children;
					const [firstSuccess, ...otherSuccesses] = successes;
					const userEmail = get(find(data, { key2: firstSuccess[0].key2 }), 'xEmail', '');
					const key = type === 'users' ? userEmail : firstSuccess[0].keyDescription;
					firstNotification = {
						...firstNotification,
						ref: '',
						success: true,
						contentClassName: 'fullwidth w--max--570',
						alignLeft: true,
						message: joinMessage({
							additionalInfo: { key },
							message: successMessage(''),
						}),
					};
					firstNotification.children = map(otherSuccesses, ([{ key2, keyDescription }]) => {
						const userEmail = get(find(data, { key2 }), 'xEmail', '');
						const key = type === 'users' ? userEmail : keyDescription;
						return {
							success: true,
							message: joinMessage({ additionalInfo: { key }, message: successMessage('') }),
						};
					});
					firstNotification.children.push(oldFirstNotification);
				}
				each(otherNotifications, otherNotification => {
					firstNotification.children.push({
						message: joinMessage(otherNotification),
						success: false,
					});
				});
				if (!isEmpty(successes)) {
					const lastNotification = isEmpty(firstNotification.children)
						? firstNotification
						: last(firstNotification.children);

					if (!lastNotification.message) {
						lastNotification.message = successMessage();
					}
				}

				firstNotification.show(firstNotification);
			}
		} catch (e) {
			this.props.handleError(e);
		}
		this.setIsSaving();
		this.props.closeModal();
		this.props.showLoader();
	};

	onIPChange = event => {
		const { whiteListedIPs } = this.state;
		const {
			eventArgs: {
				target: { value },
			},
		} = event;
		whiteListedIPs.allIPs = value.replace(/ +?/g, '');
		this.setState({ whiteListedIPs }, this.checkFormValidity);
	};

	onIPBlur = event => {
		const { areAllIPsValid } = event;
		const { whiteListedIPs } = this.state;
		whiteListedIPs.areAllIPsValid = areAllIPsValid;
		this.setState({ whiteListedIPs });
	};

	checkFormValidity = () => {
		const errorMessages = {};
		const {
			whiteListedIPs: { areAllIPsValid },
		} = this.state;
		if (!areAllIPsValid) {
			errorMessages.whiteListedIPs = 'All IPs must be a valid IPv4 address.';
		}
		this.setState({ errorMessages });
		const anyErrors = !isEmpty(errorMessages);
		if (anyErrors) {
			this.focusErrors();
		}
		return !anyErrors;
	};

	focusErrors = () =>
		this.errorRef.current && this.errorRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });

	render() {
		const { disclaimerMessage } = this.props;
		const { errorMessages, isSaving } = this.state;

		return (
			<div className="modal">
				<div className="modal__header">
					<div className="modal__header__title">Whitelist IPs for my entire Account</div>
				</div>
				<div className="modal__body">
					<div ref={this.errorRef}>{!isEmpty(errorMessages) && <FormErrors errors={errorMessages} />}</div>
					<div className="row spc--bottom--sml">
						<div className="col col-sml-12">
							These settings apply across your entire Sola account and will affect all users on your Sola
							account.
						</div>
					</div>
					<div className="row">
						<div className="col col-sml-12">
							<h5 className="type--base type--wgt--medium">Whitelist IPs</h5>
							<div className="message message--default display--b spc--bottom--sml">{disclaimerMessage}</div>
							<IpInput
								id="whiteListedIPs"
								name="whiteListedIPs"
								value={this.state.whiteListedIPs.allIPs}
								onChange={this.onIPChange}
								onBlur={this.onIPBlur}
							></IpInput>
						</div>
					</div>
				</div>
				<div className="modal__footer">
					<button
						type="button"
						className="btn btn--primary btn--sml spc--left--sml"
						onClick={this.save}
						disabled={!isEmpty(errorMessages) || isSaving}
					>
						Apply
					</button>
				</div>
			</div>
		);
	}
}

WhitelistIPsPopup.propTypes = {
	makePendingRequest: func.isRequired,
	addNotification: func.isRequired,
	showLoader: func.isRequired,
	handleError: func.isRequired,
	disclaimerMessage: any.isRequired,
	data: array.isRequired,
	closeModal: func.isRequired,
	type: string.isRequired,
};

export default withCancelable(WhitelistIPsPopup);
